module.exports = [{
      plugin: require('/home/lex/repos/react-ru/node_modules/gatsby-plugin-glamor/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/lex/repos/react-ru/node_modules/gatsby-plugin-twitter/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/lex/repos/react-ru/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[],"color":"#61dafb"},
    },{
      plugin: require('/home/lex/repos/react-ru/node_modules/gatsby-remark-autolink-headers/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/lex/repos/react-ru/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-122493965-1"},
    },{
      plugin: require('/home/lex/repos/react-ru/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/lex/repos/react-ru/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
